.body{
    background-color:rgb(255, 247, 233);
}
.agritech{
   
    background-color: #101318;
}
.agritechImg{
    width: 75%;
    
}
.agriDetails{
    background-size: cover;
    display: grid;
  

}
.AI{
    background: url(../assets/AI.png);
    background-size: cover;
}
.cloud{
    background: url(../assets/cloud.png);
    background-size: cover;
}
.data{
    background: url(../assets/data.png);
    background-size: cover;
}
.lead{

    font-size: medium;
}
.agriHeading{
    color: rgb(255, 80, 109);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 800;
    font-style: normal;
    padding: 5%;
}
.agriPara{
    font-size: 18px;
    color: rgb(0, 0, 0);
    line-height: 38px;
    font-weight: 500;
    padding: 1%;
margin-right: 0%;
}
.agriSubPara{
    font-size: 14px;
    color: rgb(0, 0, 0);
    /* line-height: 38px; */
    font-weight: 500;
    padding: 1%;
margin-right: 0%;
}
.agritechSection1{
    padding-top: 8% ;
    padding-bottom: 8%;
   
}
.agritech3{
    background: url(../assets/agritech3.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-color: #101318;
    border-width: 1px;
    box-shadow:  5px 5px 6px #848484,
    -5px -5px 6px #cbcbcb;
}
.agritech4{
    background: url(../assets/smart-farming-with-agriculture-iot.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:  5px 5px 6px #848484,
    -5px -5px 6px #cbcbcb;
}
.watertech5{
    background: url(../assets/waterSustain.png);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:  5px 5px 6px #848484,
    -5px -5px 6px #cbcbcb;

}

/* --------------- */

.containers{
    display: flex;
    margin: 1vmax auto;
    width: 90vw;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
  padding-top: 5%;
  background:#dcdcdc;
}

.cardContain{
    width: 25vmax;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    margin: 2vmax;
    transition: all 0.5s;
    padding-bottom: 0.5vmax;
}
.cardContain > svg {
    margin: auto;
    

}
.containerSection{
    padding: 0vmax 15vmax 0vmax 15vmax;
   
    margin: auto;
}
@media screen and (max-width: 768px){
    .containerSection{
        padding: 5px;
    }
    .row{
        padding: 2px;
        margin: 0%;
        width: 100vw;
          
    }
    .cardContain{
        width: 100vw;
        
    }
}