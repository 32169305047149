.body{
    background-color:rgb(255, 247, 233);
}


.platform1{
    background: url(../assets/ItPlatform.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-color: #101318;
    border-width: 1px;
    box-shadow:  5px 5px 6px #848484,
    -5px -5px 6px #cbcbcb;
}
.agritech4{
    background: url(../assets/smart-farming-with-agriculture-iot.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:  5px 5px 6px #848484,
    -5px -5px 6px #cbcbcb;
}
.watertech5{
    background: url(../assets//waterSustain.png);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow:  5px 5px 6px #848484,
    -5px -5px 6px #cbcbcb;

}

/* --------------- */

.containers{
    display: flex;
    margin: 1vmax auto;
    width: 90vw;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 100%;
  padding-top: 5%;
  background:#dcdcdc;
}

.cardContain{
    width: 25vmax;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    margin: 2vmax;
    transition: all 0.5s;
    padding-bottom: 0.5vmax;
}
.cardContain > svg {
    margin: auto;
    

}