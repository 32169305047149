body{
    background-color: white;
}
.start{
    width: 80%;
    height: 50%;
    
}
.vision{
       background: url(../assets/aboutUs2.png);
   background-size: cover;
   background-repeat: no-repeat;
width: 100%;
    
}
.visionHeading{
    font-size: 3.5rem;
    text-decoration: underline orangered;
    text-underline-offset: 8px;


}
.mission{
    padding-right: 20px;
    background: url(../assets/aboutUs3.png);
    background-size:contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: end;
}
.text2{
    width: 60%;
    padding: 5% 5% 10% 15%;
    justify-content: right;
    font-size: medium;
    font-weight: 500;
    color: rgb(75, 75, 75);
}
.text{
 font-weight: 500;
 color: rgb(75, 75, 75);
font-size: medium;
}
.text1{
width: 55%;
    padding: 6%;
    gap: 4px;
    justify-content: left;
    align-content: flex-start;
    font-size: medium;
    font-weight: 500;
    color: rgb(75, 75, 75);
}
.left2{
    width: 100%;
    height: 100%;
}
.goal{
    padding-right: 20px;
    display: 'flex';
    background: url(../assets/goals.png);
    background-size: cover;
    background-repeat: no-repeat;
}
@media (max-width: 768px) {
    .start {
      width: 100%; /* Adjusted for mobile */
    }
    .goal{
width: 100%;
        background: none;

    }
    .mission{
        width: 100%;
        background:none;
     
    }
    .vision{
        background: none;
    
 width: 100%;
     
 }
    .text2,
    .text1 {
      width: 100%;
      padding: 10px; /* Adjusted for mobile */
    }
  }