
  
 .footer{
    width: 100%;
 }
  .main-footer {
  padding: 20px 0px 20px 0px;
    display: flex;
    justify-content: space-evenly;
    background: url(../assets/booking.png);
    background-repeat: no-repeat;
    background-size:auto;
    width: 100%;
  }
  
  .main-footer ul {
    list-style: none;
  }
  
  .main-footer h1 {
    font-size: 22px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: 500;
  }
  
  .main-footer h2 {
    color: #ffffff;
    font-weight: 500;
  }
  
  .main-footer ul li a {
    color: #ffffffcc;
    text-decoration:none;
  }
  
  footer {
    background-color: #000000 ;
    font-size: 17px;
  padding: 10px 0px 10px 0px;
    color: #ffffff;
    text-align: center;
    width: 100%;
  }
  
  footer a {
    text-decoration: none;
    color: #ffffff;
  }
  
  .logoinfo p {
    color: #ffffff;
    font-size: 17px;
    margin-top: 5px;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-details li {
    list-style: none;
    margin: 10px 0;
  }
  
  .contact-details li a {
    text-decoration:none;
    color: #f1f1f1;
  }
  
  .contact-details .fa {
    color: #f1f1f1;
    /* margin-right: 10px; */
  }
  
  .sociallogos{
    padding:20px 0;
  }
  
  .sociallogos .logobox a{
    padding:0 10px;
    text-decoration:none;
    color:#ffffff;
    font-size:22px;
  }
  .com{
    left: 0;

  }
  .com ul li{
    left: 0;
    padding: 5px;
  }
  
  @media only screen and (max-width: 768px) {
    .main-footer {
      /* padding:20px; */
      display:grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      /* z-index: 3; */
    }
      .info{
        padding:20px 0;
    }
    footer{
      width: 100%;
      height: auto;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .main-footer {
      grid-template-columns: 1fr;
      width: 100%;
    }
    .sociallogos{
      padding:20px 0;
    }
    .com{
      padding:20px 0;
    } 
    footer{
      width: 100%;
      height: auto;
    }
  /* .working img .working-img{
    width: auto;
    height: auto;
  } */
  }