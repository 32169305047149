/* TestimonialCard.css */
.testimonial-card {
  background-color: aliceblue;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 10px;
 
    border-radius: 10px;
    box-shadow: 0px 0px 11px 3px rgba(73, 73, 73, 0.1);
  }
  
  /* Testimonials.css */
  .testimonial-text{
  padding: 2vmax;
    height: 15vmax;
    align-items: center;
    font-size:18px;
    font-weight: bolder;
    color: gray;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

  }
  .testimonial-author{
   position: relative;
    text-align: center;
    font-size:14px;
    font-weight: bold;
    color: black;
    bottom: 0;
    

  }
  @media screen and (max-width: 768px){
.testimonial-card{
 height: 40vmax;

}
.testimonial-text{
  font-size: small;
}
.testimonial-author{
  bottom: 0;
  margin-top: 10%;
}
  }
    
  

 