@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Mono:400,400i|IBM+Plex+Sans+Condensed:400,400i|IBM+Plex+Sans:100,100i,400,400i,700,700i|IBM+Plex+Serif:400,400i');
@import url('https://kit.fontawesome.com/297a71943d.js');


body {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  /* color: gray; */
  font-weight: 400;
  margin: 0;
  text-align: left;
  padding: 0;
  /* background-color: #b4b4b4; */
  
}

.homeImg {
   /* Adjust the image size as needed */
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  animation: slidin 6s ease-in-out 1 forwards;
}




/* -----about------ */
#about{
   padding: 10% 5% 10% 8%;
   color: #949494;
   background-color: aliceblue;
   background: url(../assets/aboutImg.png);
   background-repeat: no-repeat;
   background-size: cover;
 
}
#about p{
   font-size: 22px;
   text-align: justify;
   box-shadow: 10px 10px 11px 0px rgb(224, 224, 224);
   border-radius: 20px;
   padding:10px;
   /* font-family: Arial, Helvetica, sans-serif; */
}
.row1{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;  
}
.about-col-1{
   flex-basis: 35%;     
   }
.about-col-1 img{
   width: 110%;
   height: 110%;
   border-radius: 15px;
   box-shadow: 0px 0px 20px 5px rgb(230, 111, 14) ;
   /* animation: slidin 2s ease-in-out 1 forwards; */
   

}    
.aboutImg{
  width: fit-content;
  height: fit-content;
}
.about-col-2{
   flex-basis: 60%;
   border-radius: 50px;
   /* box-shadow: 0px 0px 6px 4px grey; */
}
.sub-title{
   font-size: 50px;
   font-weight: 600;
   color: rgb(0, 0, 0);
 
   border-radius: 30px;
   width: auto;
   padding: 10px;

}

@keyframes slidin {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}


/* services */
#services{

   padding: 30px ;
   z-index: 9; 
   /* background-color: rgb(224, 241, 255);  */
   background: url(../assets/servicesImg.png);
   background-repeat: no-repeat;
   background-size: 100%;
}
.tit:hover{
  animation: slidin 2s ease-in-out 1 infinite;
   /* transform:translateY(-10px); */
   font-weight: 600;
   text-align: left;
}
.services-list{
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
   grid-gap: 40px;
   margin-top: 5%;
   color: rgb(58, 58, 58);
}
.services-list .servicesDiv{
   background: #ffffff;
   padding-top: 10px;
   padding-bottom: 4vmax;
   font-size: medium;
   color: rgb(92, 92, 92);
   border-radius: 10px;
   box-shadow: 0px 0px 1px 1px rgb(230, 111, 14);
   transition: background 0.5s, transform 0.5s;
}
.services-list .servicesDiv p{
  text-align: justify;
 
  padding: 10px 15px 10px 15px;
}
.services-list .servicesDiv i{
   font-size: 70px;
   margin-bottom: 30px;
}
.services-list .servicesDiv img{
   width: 150px;
   margin-top: 10%;
   /* margin-bottom: 30px; */
}
.services-list .servicesDiv h2{
   font-size: 30px;
   font-weight: 800;
   margin-bottom: 15px;
   margin-top: 30px;
   
 
}
.services-list .servicesDiv a{
   text-decoration: none;
   color: rgb(255, 255, 255);
   font-size: 12px;
   margin-top: 1.5vmax;
   display: inline-block;
}
.services-list .servicesDiv:hover{
   background: orange;
   transform: translateY(-10px);
   color: white;

}


.servicesImg{
  height: 35%;
 transition: transform 0.5s;
}
.servicesImg2{
  height: 35%;
  transition: transform 0.5s;
}
.servicesImg3{
  height: 35%;
  transition: transform 0.5s;
}

/* -------testimonials---------- */

.testimonial{
  height:fit-content;
  background: url(../assets/aboutImg.png);
  background-repeat: no-repeat;
  background-size:cover;
  padding: 100px 0px 100px 0px;

}

/* ------------------------ */
.Products{

background: url(../assets/appointmentImg.png);
background-size: contain;

  
  
}
.Product1{
 padding: 0px 150px 0px 150px;
 display: flex;
 justify-content: space-between;
 


 width: 100%;
}








.aiStack{
  width: 30%;
  padding: 5% 0 5% 0;
  
}
.IOT{
  width: 30%;
padding: 5% 0 5% 0;
}

.stackH{
  padding-top: 30px;
  padding-bottom: 30px;
  font-size:xx-large;
  font-weight: 1000;
  color: #000000;
  text-decoration: underline orangered;
  text-underline-offset: 8px;
  text-shadow: 1px 1px #c9c9c9;
  font-variant:small-caps;
  font-style: oblique;
}

.para1{
  
  padding: 8% 10% 0% 10%;
  color: gray;
  text-align: justify;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 1px rgb(202, 202, 202);

}
/* ---------------------------- */
.contactForm{
  display: flex;
  background: url(../assets/contactImg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 60px;
  padding-bottom: 15%;
}
.contact-left{
  flex-basis: 35%;
  z-index: 1;
}

.contact-right{
  flex-basis: 60%;
  z-index: 9;
}



.btn.btn2{
  display: inline-block;
  background: orangered;
  z-index: 9;
  color: white;
  border: none;
  border-radius: 50px;

}
.contact-right form{
  width: 100%;

}
form input, form textarea{
  width: 100%;
  border: 0;
  outline: none;
  background: #f9bd68;
  padding: 15px;
  margin: 15px 0;
  color: #000000;
  font-size: 18px;
  border-radius: 6px;

}
form .btn2{
  padding: 14px 60px;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
  z-index: 9;
}
#msg{
  color: #61b752;
  display: block;
margin-top: -20px; 
}
/* mobile size */
@media screen and (max-width: 1200px) {
 
}
@media screen and (max-width: 768px) {
  .services-list .servicesDiv{
    padding-bottom: 15%;
  }
  .contactForm, .contact-left, .contact-right{
    flex-basis: 100%;
    flex-direction: column; /* Stack items vertically on small screens */
  align-items: center; /* Center items horizontally on small screens */
justify-content: center;   
    padding: 10px;
  }
  .sub-title{
    font-size: 30px;
    }

  .servicesDiv{
    padding-left: 2px;
    padding-right: 2px;
  }

  .swiper-container {
    height: auto; /* Adjust the height for smaller screens */
  }

  .slide-image {
    display: none;
    width: 50%; /* Make the image full-width on smaller screens */
    max-height: 40vh; /* Limit the image height on smaller screens */
  }

  .content {
    align-items: center; /* Center the content on smaller screens */
    text-align: center; /* Center the text on smaller screens */
  }
#about{
  background-size: cover ;
}
  .about-col-1 {
    flex-basis: 100%; /* Full width for the image on smaller screens */
    margin-bottom: 20px; /* Add some space between the image and text on smaller screens */
  }
.about-col-1 .aboutImg{
  width:150px;
  
}
  .about-col-2 {
    flex-basis: 100%;

     /* Full width for the text on smaller screens */
  }
  .about-col-2 .aboutText{
    font-size: 5px;
  }

  .appointmentcontain {
    padding-left: 5%;
    padding-right: 5%;
  }

  .contactForm{
    background: url(../assets/contactImg.png);
    background-repeat: no-repeat;
    background-size:cover;
  }
  .contact-left{
  
    padding-right: 15px;
  }

  .Appointment h2,
  .Appointment p,
  .Appointment .input,
  .Appointment button {
    width: 100%; 
    padding: auto;
    margin-top: 5%;
    /* Make these elements full-width on smaller screens */
  }
  /* ------------------------- */
  .Products{
    display: block;
    margin: 10%;
  }
  .Product1{
    margin-left: 5px;
  }
  .Product1
 {
  flex-direction: column; /* Stack items vertically on small screens */
  align-items: center; /* Center items horizontally on small screens */
justify-content: center;   
    padding: 10px;
 
   
    width: 100%;
    border-radius: 10%;
    margin: auto;
   margin-top: 3%;
  }
  .para1{
    padding: 10px 0px 10px 20px;
  }

  .aiStack{
    width: 50%;
    /* padding: 4% 8% 0 0; */
    
  }
  .IOT{
    width: 50%;
  /* padding: 5% 0 5% 0; */
  }
}
/* ------------------------- */
#portfolio{
  padding: 50px 0;
  z-index: 9;
  background:url(../assets/servicesImg.png);
  background-size: 100%;
  background-repeat: no-repeat;
 
}
.work-list{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 40px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 50px;
}
.work{
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  height: 250px;
}
.work img{
  width: 100%;
  height:100% ;
  border-radius: 10px;
  display: block;
  transition: transform 0.5s;
}
.layer{
  width: 100%;
  height: 0;
  background: linear-gradient(rgba(173, 173, 173, 0.6), orange) ;
  border-radius: 10px;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  font-size: 14px;
  transition: height 0.5s;
  z-index: 9
  ;
}
.layer h3{
  font: weight 500; 
  margin-bottom: 20px;
  z-index: 9;
}
.layer a{
  margin-top: 20px;
  color: orangered;
  text-decoration: none;
  font-size: 18px;
  line-height: 60px;
  background: #ffffff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  z-index: 9;
}
.work:hover img{
  transform: scale(1.1) ;
}
.work:hover .layer{
  height: 100%;
  z-index: 9;
}
.btn{
  display: block;
  margin: 50px auto;
  width: fit-content;
  border: 1px solid #ff004f;
  padding: 14px 50px;
  border-radius: 6px;
  text-decoration: none;
  color: white;
  transition: background 0.5s;
  z-index: 9;
}
.btn:hover{
  background: orange;
  z-index: 9;


}