#loading {
	position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
    background-color: white;
}
img{
    width:98px ;
}

.loader {
    position: absolute;
    width: 100px; /* Reduce the size of the loader */
    height: 100px; /* Reduce the size of the loader */
    border: 5px solid #fab453;
  border-top: 5px solid rgb(255, 94, 0);
  border-left: 5px solid rgb(255, 172, 117) 58, 58;
    border-radius: 50%;
    animation: load 2s ease-in-out infinite forwards;
  }
  
  @keyframes load {
    0% {
      transform: rotate(0);
    }
    50% {
      transform: rotate(360deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
  
  .loader span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
  }
  
  .loader img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    transform: rotate(0deg); /* Keep the image still */
  }